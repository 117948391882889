import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signOut, onAuthStateChanged } from 'firebase/auth'; // Add onAuthStateChanged
import { auth, db } from '../firebaseConfig.js'; // Import Firestore DB
import { doc, getDoc } from 'firebase/firestore'; // Firestore methods
import '../styles/dashboard/dashboard.css';
import { Link } from 'react-router-dom';

const Dashboard = ({ clientWebsiteName = "Client's Website" }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // Fetch user data from Firestore when user is authenticated
  useEffect(() => {
    const fetchUserData = async (userId) => {
      try {
        const userRef = doc(db, 'users', userId);
        const userDoc = await getDoc(userRef);

        if (userDoc.exists()) {
          const paymentHistory = userDoc.data().paymentHistory || [];
          const successfulPayment = paymentHistory.find(payment => payment.paymentStatus === 'Paid');

          if (successfulPayment) {
            setUserData({
              name: successfulPayment.name,
              phone: successfulPayment.phone,
              websiteUrl: successfulPayment.websiteUrl,
              expiryDate: successfulPayment.expiryDate,
              selectedPlan: successfulPayment.selectedPlan
            });
          }
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setLoading(false); // Set loading to false after fetching data
      }
    };

    // Listen for authentication state changes
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchUserData(user.uid); // Get user data when a user is logged in
      } else {
        navigate('/login'); // Redirect to login if no user is authenticated
      }
    });

    return () => unsubscribe(); // Clean up listener on component unmount
  }, [navigate]);

  // Handle logout logic
  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        navigate('/');
      })
      .catch((error) => {
        console.error('Error logging out:', error);
      });
  };

  const toggleMenu = () => setMenuOpen(!menuOpen);

  return (
    <div className="dashboard-container">
      {loading ? (
        <p>Loading...</p> // Show a loading message while data is being fetched
      ) : (
        <>
          {/* Hamburger Menu for Small Screens */}
          <div className={`hamburger-menu ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
            <span className="hamburger-icon">☰</span>
          </div>

          {/* Sidebar */}
          <aside className={`sidebar ${menuOpen ? 'open' : ''}`}>
            <div className="logod">
              <img src="images/3.png" alt="Brand Logo" className="logod-image" />
            </div>
            <nav className="menu">
              <Link to="/" className="menu-item active">Home</Link>

              <Link to="/Call" className="menu-item">Support</Link>
              <div className="menu-item" onClick={handleLogout}>Logout</div>
            </nav>
          </aside>

          {/* Main Content */}
          <main className={`main-content ${menuOpen ? 'blurred' : ''}`}>
            {userData ? (
              <div className="user-details">
                <h2>Welcome, {userData.name}</h2>
                <p className="phone">Phone: {userData.phone}</p>

                {/* Details Row (Website, Expiry, and Plan) */}
                <div className="details-row">
                  <div className="detail-item">
                    <h4>Website</h4>
                    <p>{userData.websiteUrl}</p>
                  </div>
                  <div className="detail-item">
                    <h4>Expiry Date</h4>
                    <p>{userData.expiryDate}</p>
                  </div>
                  <div className="detail-item">
                    <h4>Plan</h4>
                    <p>{userData.selectedPlan}</p>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <h1 className="clientsite">{clientWebsiteName}</h1>
                <h2 className="dashboard-title">Dashboard</h2>
              </>
            )}


            {/* Dashboard Cards */}
            <div className="card-grid">
              <div className="card">
                <p className="card-title">Keyword Ranking</p>
                <p className="card-placeholder">No data available yet</p>
              </div>
              <div className="card">
                <p className="card-title">Website Traffic</p>
                <p className="card-placeholder">No data available yet</p>
              </div>
              <div className="card large-card">
                <p className="card-title">Performance Insights</p>
                <p className="card-placeholder">No data available yet</p>
              </div>
            </div>

            {/* Explore Plans Section */}
            {!userData && (
              <div className="explore-container">
                <p className="explore-message">
                  It appears you haven't purchased a plan yet. Unlock more insights by choosing a suitable plan.
                </p>
                <Link to='/Pricing'>
                  <button className="explore-button">Explore Plans</button>
                </Link>
              </div>
            )}
          </main>
        </>
      )}
    </div>
  );
};

export default Dashboard;
