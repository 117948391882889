import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { auth } from '../../firebaseConfig'; // Ensure this path is correct
import { onAuthStateChanged } from 'firebase/auth';

// Import your styles
import './Header.css';

const Header = () => {
  const [user, setUser] = useState(null);
  const location = useLocation(); // Get current location

  // User authentication listener
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user || null); // Set user state based on authentication status
    });

    return () => unsubscribe(); // Cleanup subscription on unmount
  }, []);

  // Define paths where you want to hide the header
  const hideHeaderPaths = ['/Paymentbase', '/login', '/signup', '/reset-password', '/Dashboard', '/Paynow', '/TermsAndConditions', '/dashboard',];

  // Check if current path is in the hideHeaderPaths array
  const shouldHideHeader = hideHeaderPaths.includes(location.pathname);

  if (shouldHideHeader) return null; // Don't render the header if the path matches

  return (
    <div>
      <div className="header">
        <Link to="/">
          <img className="logo" src="images/3.png" alt="logo" />
        </Link>
        <Link to="/">
          <div className="welcome">Welcome</div>
        </Link>
        <Link to="/discover" className="discover">
          <div>Discover</div>
        </Link>
        <Link to="/pricing" className="pricing">
          <div>Pricing</div>
        </Link>
        <Link to="/call" className="call">
          <div>Call </div>
        </Link>
      </div>
      <nav>
        {user ? ( // Conditional rendering based on user authentication
          <div className="dashboard-icon">
            <Link to="/Dashboard">
              <i className="fas fa-tachometer-alt"></i> Dashboard
            </Link>
          </div>
        ) : (
          <Link to="/login">
            <div className="login-icon">
              <i className="fas fa-user"></i> Login
            </div>
          </Link>
        )}
      </nav>
    </div>
  );
};

export default Header;
