// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";  // Import getAuth for authentication
import { getFirestore } from "firebase/firestore";  // Import Firestore
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAJNerAxi_ErZCRojEexN1IsWQeSXVzxRM",
  authDomain: "vyrexauth.firebaseapp.com",
  projectId: "vyrexauth",
  storageBucket: "vyrexauth.appspot.com",
  messagingSenderId: "101875280352",
  appId: "1:101875280352:web:11e07da3c0db25f3aea385",
  measurementId: "G-B3V0XHNBJZ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and export it
export const auth = getAuth(app);  // Export auth for use in other files

// Initialize Firestore
export const db = getFirestore(app);  // Export db for Firestore use

// Initialize Analytics if needed
const analytics = getAnalytics(app);
