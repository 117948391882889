import '../styles/side-section-css/terms.css';
import React from 'react';

const TermsAndConditions = () => {
    return (
        <div className="terms-container">
            <h1>Terms and Conditions</h1>
            <p>Effective Date: October 2, 2024</p>


            <h2>1. Introduction</h2>
            <p>
                Welcome to Vyrex (the “Website”). These Terms and Conditions ("Terms") govern your use of
                Vyrex's SEO services. By accessing or using our services, you agree to abide by these Terms.
                If you disagree with any part of these Terms, please do not proceed with our services.
            </p>

            <h2>2. Services Provided</h2>
            <p>
                Vyrex specializes in search engine optimization (SEO) services, including but not limited
                to keyword research, on-page optimization, link building, performance analytics, and content
                strategy. While Vyrex uses best practices and industry-standard techniques, results may vary
                due to the dynamic nature of search engines.
            </p>
            <p>
                <strong>Disclaimer:</strong> Vyrex does not guarantee specific search engine rankings or
                outcomes due to factors beyond our control, such as algorithm changes or competitors’ activities.
            </p>

            <h2>3. Client Obligations</h2>
            <p>
                Clients must provide accurate, up-to-date information and access to necessary tools
                (e.g., CMS, Google Analytics). Failure to provide access or cooperate with Vyrex's
                requests may delay or hinder SEO progress.
            </p>
            <p>You agree to:</p>
            <ul>
                <li>Not use the Website or our services for illegal activities.</li>
                <li>Provide all requested materials or information in a timely manner.</li>
                <li>Comply with all applicable laws, rules, and regulations.</li>
            </ul>

            <h2>4. Payment Terms</h2>
            <p>
                <strong>Pricing and Fees:</strong> Our fees for SEO services are clearly outlined on our
                website or communicated directly during the onboarding process. Prices are subject to change,
                and Vyrex reserves the right to adjust pricing based on changes in project scope.
            </p>
            <p>
                <strong>Billing and Payment:</strong> Payments must be made in full as per the agreed
                schedule (monthly, quarterly, etc.). Failure to pay on time may result in suspension or
                termination of services. No work will commence until payment is received.
            </p>
            <p>
                <strong>Refunds:</strong> Vyrex does not offer refunds on SEO services once work has begun,
                as SEO is an ongoing process.
            </p>

            <h2>5. Performance Reporting</h2>
            <p>
                Vyrex provides regular performance reports to clients, detailing the progress of SEO efforts.
                While we aim for transparency and clarity, you acknowledge that SEO performance is subject to
                various external factors, including search engine algorithm updates, and may not always be
                within our control.
            </p>

            <h2>6. Intellectual Property</h2>
            <p>
                All reports, strategies, and documents produced by Vyrex during the provision of SEO services
                are owned by Vyrex until all payments are fully made. Upon full payment, ownership rights are
                transferred to the client. However, Vyrex retains the right to use non-proprietary SEO
                strategies for other clients.
            </p>

            <h2>7. Third-Party Tools and Services</h2>
            <p>
                Vyrex may use third-party tools, platforms, or services (e.g., Google Analytics, SEO software)
                to deliver SEO results. We are not responsible for any issues or limitations arising from
                third-party services, including their accuracy, availability, or performance.
            </p>

            <h2>8. Confidentiality</h2>
            <p>
                Both parties agree to maintain confidentiality regarding sensitive information such as
                business practices, proprietary strategies, and any non-public data shared during the SEO process.
            </p>

            <h2>9. Warranties and Limitation of Liability</h2>
            <p>
                <strong>No Guarantee of Results:</strong> Vyrex cannot guarantee specific rankings or traffic
                improvements due to the ever-changing nature of search engine algorithms and external factors.
            </p>
            <p>
                <strong>Liability Limitation:</strong> Vyrex will not be liable for any indirect, incidental,
                or consequential damages arising from the use or inability to use our services, even if
                advised of the possibility of such damages. Vyrex's total liability will be limited to the
                amount paid for the services.
            </p>

            <h2>10. Termination of Services</h2>
            <p>
                Vyrex reserves the right to terminate services if the client fails to make payments or breaches
                these Terms. Clients may terminate services with 30 days’ written notice. Any outstanding
                payments must be settled prior to termination.
            </p>

            <h2>11. Indemnification</h2>
            <p>
                You agree to indemnify and hold Vyrex harmless from any claims, damages, or losses (including
                legal fees) resulting from your use of our services, your violation of these Terms, or any
                content you submit or make available through our services.
            </p>

            <h2>12. Governing Law</h2>
            <p>
                These Terms are governed by and construed in accordance with the laws of [Insert Jurisdiction],
                and you agree to submit to the exclusive jurisdiction of the courts located in [Insert Region].
            </p>

            <h2>13. Changes to Terms</h2>
            <p>
                Vyrex may modify these Terms at any time, and any changes will be effective upon posting to the
                Website. By continuing to use our services after changes are posted, you agree to the updated Terms.
            </p>

            <h2>14. Contact Information</h2>
            <p>
                If you have any questions regarding these Terms and Conditions, please contact us at:
            </p>
            <p>Email: [info@vyrex.net]</p>
        </div>
    );
};

export default TermsAndConditions;
