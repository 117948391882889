// components/ResetPassword.js
import '../styles/login/ResetPassword.css'; // Optional: Use the same styles as AuthPage
import React, { useState } from 'react';
import { auth } from '../firebaseConfig';
import { sendPasswordResetEmail } from 'firebase/auth';
import { useNavigate } from 'react-router-dom'; // To navigate after submitting

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState(''); // State for messages
  const navigate = useNavigate(); // Hook for redirection

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setMessage(''); // Clear any previous messages

    try {
      await sendPasswordResetEmail(auth, email);
      setMessage('Password reset email sent! Please check your inbox.'); // Success message
      // Optionally redirect after a short delay
      setTimeout(() => {
        navigate('/login'); // Redirect to login page after successful submission
      }, 2000);
    } catch (error) {
      setMessage('Failed to send password reset email. Please try again.'); // Error message
    }
  };

  return (
    <div className="auth-page">
      <header className="headera">
        <h1 className="brand">Vyrex</h1>
      </header>

      <div className="auth-container">
        <form onSubmit={handleResetPassword} className="auth-form">
          <h2>Reset Password</h2>

          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />

          {message && <p className="message">{message}</p>} {/* Display feedback messages */}

          <button type="submit" className="auth-btn">
            Send Reset Email
          </button>

          <p onClick={() => navigate('/login')} className="toggle-link">
            Back to Login
          </p>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
