import React from 'react';
import { Helmet } from 'react-helmet';

const SEOComponent = ({ title = "Vyrex | Free Lite SEO Plan & Affordable Full SEO at $250",
  description = "Vyrex offers two tailored SEO plans: a free lite plan to get started and an affordable full-service plan at just $250. Boost your online visibility with our expert strategies today!" }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content="SEO services, affordable SEO, free SEO plan, digital marketing, online visibility, search engine optimization, affordable SEO pricing, Vyrex SEO" />
      <meta name="robots" content="index, follow" />
      <meta name="author" content="Vyrex Team" />
      <link rel="canonical" href="https://vyrex.net/" />

      {/* Open Graph Tags for Social Media Sharing */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content="https://vyrex.net/" />
      <meta property="og:type" content="website" />
      <meta property="og:image" content="https://vyrex.net/path-to-image.jpg" /> {/* Add a relevant image URL */}
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />

      {/* Twitter Card Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content="https://vyrex.net/path-to-image.jpg" /> {/* Add a relevant image URL */}
    </Helmet>
  );
};

export default SEOComponent;
