import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/home.js';
import Discover from './components/discover.js';
import Pricing from './components/pricing.js';
import Call from './components/call.js';
import Paymentbase from './components/payment/payment-base.js';
import FreeSection from './components/payment/freesection.js';
import Login from './components/login';
import SignUp from './components/signup.js';
import ResetPassword from './components/ResetPassword';
import Dashboard from './components/Dashboard';
import Header from './components/header/Header.js';
import TermsAndConditions from './components/terms.js';
import SEOComponent from './components/SEOComponent'; // Import SEO component

function App() {
  return (
    <Router>
      <Header />
      <SEOComponent /> {/* Add SEO component here to apply global SEO tags */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/discover" element={<Discover />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/call" element={<Call />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/paymentbase" element={<Paymentbase />} />
        <Route path="/freesection" element={<FreeSection />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      </Routes>
    </Router>
  );
}

export default App;
